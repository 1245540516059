import React from 'react';
import SpriteIconViewer from '@components/Icons/SpriteIconViewer';

const EventsPageEmptyState = () => {
  return (
    <div className="article-page-empty-state">
      <div className="empty-search-icon-container">
        <SpriteIconViewer className="empty-search-icon" spriteId="newspaper" />
      </div>
      <h1 className="empty-main-message">No available events</h1>
      <h4 className="empty-sub-message">
        We'll let you know when we got events for you.
      </h4>
    </div>
  );
};

export default EventsPageEmptyState;
