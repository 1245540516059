import React from 'react';
import { Link } from 'gatsby';

import Image from '@components/Image';
import Button from 'antd/lib/button';
import MarkdownViewer from '@components/MarkdownViewer';
import CardCategory from '@components/Cards/CardCategory';
import SpriteIconViewer from '@components/Icons/SpriteIconViewer';

const sanitizeContent = (input) => {
  if (typeof input !== 'string') return '';
  return input.replace('\n', '');
};

const EventsHeadline = (props) => {
  const title = props.title || 'Untitled';
  const datetime = props.datetime || 'Undated';
  const tag = props?.tag;
  const slug = props?.slug;
  const author = props.author == null ? 'Unauthored' : `by ${props.author}`;
  const location = props?.location || null;
  const cover = props.cover;
  const thumbnail = props.thumbnail;
  const content = sanitizeContent(props?.content);

  if (!slug) return <></>;

  return (
    <section className="headline-container">
      <div className="headline-left-panel">
        {cover ? (
          <Image className="headline-image-container-desktop" image={cover} />
        ) : (
          <div className="calendar-icon-container desktop">
            <SpriteIconViewer
              className="calendar-icon"
              spriteId={'calendar_icon'}
            />
          </div>
        )}

        {thumbnail ? (
          <Image className="headline-image-container-phone" image={thumbnail} />
        ) : (
          <div className="calendar-icon-container phone">
            <SpriteIconViewer
              className="calendar-icon"
              spriteId={'calendar_icon'}
            />
          </div>
        )}
      </div>

      <div className="headline-right-panel">
        <div className="category-tag-group">
          <CardCategory label={datetime} />

          {tag && (
            <div className="headline-tag-container">
              {<p className="headline-tag">{tag}</p>}
            </div>
          )}
        </div>
        <h1 className="headline-title-container">{title}</h1>

        <h3 className="details-author">{author}</h3>

        {location?.name && (
          <div className="details-location-container">
            <div className="details-location-icon">
              <SpriteIconViewer spriteId={location?.icon} />
            </div>
            <h3 className="details-location-name">{location?.name}</h3>
          </div>
        )}

        <div className="headline-content-container">
          <MarkdownViewer className="headline-content" source={content} />
        </div>
        <Link to={`/events/${slug}`}>
          <Button type="primary" className="headline-read-more-container">
            Read more
          </Button>
        </Link>
      </div>
    </section>
  );
};

export default EventsHeadline;
