import React from 'react';
import Topbar from '@components/Topbar';
import EventCardsViewer from '@components/ArticleCardsViewer/EventCardsViewer';
import EventsPageEmptyState from '@components/EmptyState/EventsPageEmptyState';

const ResultsPreview = ({ contents }) => {
  const visibleContents = contents;

  return (
    <div className="result-preview-container">
      <Topbar count={'Events'} />

      <EventCardsViewer
        EmptyState={<EventsPageEmptyState />}
        contents={visibleContents}
        linkRoot={'/events/'}
      />
    </div>
  );
};

export default ResultsPreview;
