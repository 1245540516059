import React from 'react';
import CalendarSearchBar from '@components/CalendarSearchBar';
import Section from '@components/Section';

const DEFAULT_HEADER = 'What is your header text?';
const DEFAULT_SUBHEADER = "Where's your Sub Header?";
const DEFAULT_SEARCH_PLACEHOLDER = '';

const MainSearch = ({
  header = DEFAULT_HEADER,
  subHeader = DEFAULT_SUBHEADER,
  searchPlaceholder = DEFAULT_SEARCH_PLACEHOLDER,
  flexSearchOptions,
  searchSuggestions,
  onQuery,
  defaultValue = '',
}) => {
  return (
    <Section className={'search-section'} color={'ash'}>
      <div className="search-main">
        <div className="search-main-banner-container">
          <h1 className="search-main-header">{header}</h1>
          <h2 className="search-main-subheader">{subHeader}</h2>
        </div>
        <CalendarSearchBar
          onQuery={onQuery}
          defaultValue={defaultValue}
          searchSuggestions={searchSuggestions}
          searchPlaceholder={searchPlaceholder}
          flexSearchOptions={flexSearchOptions}
        />
      </div>
    </Section>
  );
};

export default MainSearch;
