import React from 'react';
import Layout from '@components/Layout/LayoutTemplates/JobSeekersLayout';
import SEO from '@components/SEO';
import EventsModule from '@modules/Events/Main';

const Events = () => (
  <Layout isReleased={true} isMobileComingSoon={false}>
    <SEO title="Events" description="Events" />
    <div className="events-section">
      <EventsModule />
    </div>
  </Layout>
);

export default Events;
