import React from 'react';
import EventsHeadline from './EventsHeadline';
import ResultsPreview from './ResultsPreview';
import Section from '@components/Section';
import EmptyPage from '@components/EmptyState/EmptyPage';
import DataWrapper from '@components/DataWrapper';
import MainSearch from '@components/CalendarSearchSection/MainSearch';
import { encodeQueryParams, StringParam, ArrayParam } from 'use-query-params';
import { URL_RESULTS_PATH } from '../helpers/constants';
import { navigate } from 'gatsby';
import useCurrentPath from '@helpers/hooks/useCurrentPath';
import { stringify } from 'query-string';

import {
  SEARCH_HEADER,
  SEARCH_SUB_HEADER,
  SEARCH_PLACEHOLDER,
} from './helpers';

const Viewer = ({ data }) => {
  const {
    latestEventsData,
    moreEventsData,
    hasNoEvents,
    flexSearchOptions,
    searchSuggestions,
  } = data;

  const currentUrlPath = useCurrentPath();
  const handleSearchEvents = (response) => {
    const { query, results, start } = response;
    const encodedQuery = encodeQueryParams(
      { query: StringParam, search: ArrayParam, start: StringParam },
      { query, search: results, start }
    );
    const urlPath = `${currentUrlPath}${URL_RESULTS_PATH}`;
    const urlQueryParams = `?${stringify(encodedQuery)}`;
    navigate(`${urlPath}${urlQueryParams}`);
  };

  const coverImageUrl = latestEventsData?.cover_image?.url
    ? `${process.env.GATSBY_API_URL}${latestEventsData?.cover_image?.url}`
    : null;

  const thumbnailImageUrl = latestEventsData?.thumbnail_image?.url
    ? `${process.env.GATSBY_API_URL}${latestEventsData?.thumbnail_image?.url}`
    : null;

  return (
    <DataWrapper
      isEmpty={hasNoEvents}
      empty={
        <EmptyPage
          iconHref={'calendar'}
          mainMessage={'No available events'}
          subMessage={"We'll let you know when we got events for you."}
        />
      }
    >
      <div className="events-container">
        <MainSearch
          header={SEARCH_HEADER}
          subHeader={SEARCH_SUB_HEADER}
          searchPlaceholder={SEARCH_PLACEHOLDER}
          onQuery={handleSearchEvents}
          flexSearchOptions={flexSearchOptions}
          searchSuggestions={searchSuggestions}
        />
        <Section>
          <EventsHeadline
            title={latestEventsData?.title}
            tag={latestEventsData?.tag}
            slug={latestEventsData?.slug}
            author={latestEventsData?.author}
            location={latestEventsData?.location}
            datetime={latestEventsData?.datetime}
            content={latestEventsData?.content}
            cover={
              latestEventsData?.cover_image?.localFile?.childImageSharp
                ?.gatsbyImageData || coverImageUrl
            }
            thumbnail={
              latestEventsData?.thumbnail_image?.localFile?.childImageSharp
                ?.gatsbyImageData || thumbnailImageUrl
            }
          />
        </Section>
        <Section>
          <ResultsPreview contents={moreEventsData} />
        </Section>
      </div>
    </DataWrapper>
  );
};

export default Viewer;
