import isEmpty from 'lodash/isEmpty';
import { useStaticQuery, graphql } from 'gatsby';
import useFlexSuggestionIndex from '@helpers/hooks/useFlexSuggestionIndex';
import getEventCardsProps from '@helpers/methods/modifyContents/getEventCardsProps';

const Fetcher = ({ children }) => {
  const {
    latestStrapiEvents,
    moreStrapiEvents,
    allEventTitleSuggestions,
    flexSearchOptions,
    flexSuggestionOptions,
  } = useStaticQuery(
    graphql`
      {
        latestStrapiEvents: allStrapiEvents(
          limit: 3
          sort: { order: DESC, fields: published_date }
          filter: { published_at: { ne: null }, is_archived: { ne: true } }
        ) {
          nodes {
            creator_id
            created_by {
              username
            }
            author {
              username
            }
            cover_image {
              source_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                  }
                }
                url
              }
              cropped_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                  }
                }
                url
              }
            }
            cover_image_old: cover {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
                }
              }
              url
            }
            location {
              province {
                name
              }
              type
            }
            thumbnail_image {
              source_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, quality: 100)
                  }
                }
                url
              }
              cropped_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, quality: 100)
                  }
                }
                url
              }
            }
            thumbnail_image_old: thumbnail {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, quality: 100)
                }
              }
              url
            }
            title
            slug
            content
            strapiId
            created_at(formatString: "MMMM DD, YYYY")
            published_date
            published_at
            start_date
            start_time
            end_date
            end_time
            is_virtual_career_fair
          }
        }
        moreStrapiEvents: allStrapiEvents(
          filter: { published_at: { ne: null }, is_archived: { ne: true } }
        ) {
          nodes {
            creator_id
            created_by {
              username
            }
            author {
              username
            }
            slug
            thumbnail_image {
              source_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, quality: 100)
                  }
                }
                url
              }
              cropped_image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, quality: 100)
                  }
                }
                url
              }
            }
            thumbnail_image_old: thumbnail {
              localFile {
                childImageSharp {
                  gatsbyImageData(placeholder: BLURRED, quality: 100)
                }
              }
              url
            }
            title
            content
            strapiId
            created_at(formatString: "MMMM DD, YYYY")
            published_date
            published_at
            start_date
            start_time
            end_date
            end_time
            is_virtual_career_fair
            location {
              province {
                name
              }
              type
            }
          }
        }
        allEventTitleSuggestions: allStrapiEvents(
          sort: { fields: created_at, order: DESC }
        ) {
          nodes {
            title
          }
        }
        flexSearchOptions: localSearchEvents {
          index
          store
        }
        flexSuggestionOptions: localSearchEventsSuggestions {
          index
        }
      }
    `
  );

  let moreEventsData = getEventCardsProps(moreStrapiEvents?.nodes);
  moreEventsData = moreEventsData?.slice?.(1);

  let latestEventsData = getEventCardsProps(latestStrapiEvents?.nodes);
  latestEventsData = latestEventsData?.[0];

  const hasNoEvents = isEmpty(latestStrapiEvents?.nodes);

  const suggestedWordsIndex = useFlexSuggestionIndex(
    flexSuggestionOptions?.index
  );

  const suggestedTitlesIndex = allEventTitleSuggestions.nodes.map(
    ({ title }) => title
  );

  const searchSuggestions = [...suggestedWordsIndex, ...suggestedTitlesIndex];

  return children({
    latestEventsData,
    moreEventsData,
    hasNoEvents,
    searchSuggestions,
    flexSearchOptions,
  });
};

export default Fetcher;
